@import url("https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&display=swap");

@font-face {
  font-family: "Certificate";
  src: url("../fonts/Certificate\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.arabika {
  font-family: "Amiri", sans-serif;
  font-size: 15px;
}

.arabika-small {
  font-family: "Amiri", sans-serif;
  font-size: 14px;
}

.arabika-mid {
  font-family: "Amiri", sans-serif;
  font-size: 18px;
}

.arabika-big {
  font-family: "Amiri", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
}

.certificate {
  background-image: url("../images/border.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.certificate-wrapper {
  width: 100%;
  max-width: 622px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}

.certificate-header {
  position: relative;
  top: 0px;
}

.cert-logo {
  height: auto;
  margin-bottom: 20px;
}

.certificate-content {
  width: 100%;
}

.lineage-item {
  position: relative;
  margin-bottom: 20px;
}

.lineage-name {
  position: relative;
  white-space: normal;
  border: 2px solid;
}

.lineage-name::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 20px;
  height: 1px;
  background-color: black;
}

.lineage-item:not(:last-child) p::after {
  display: block;
}

.lineage-item:last-child p::after {
  display: none;
}

.indexNumber {
  position: absolute;
  font-size: 10px;
  top: 35px;
  left: 35%;
  transform: translateX(-35%);
  padding: 0px 6px;
  background-color: black;
}

/* .bio-table {
  font-size: 0.75rem;
  border-collapse: collapse;
  font-family: "Poppins", sans-serif;
} */

.bio-table th,
.bio-table td {
  padding: 4px 6px;
}

.certificate-section {
  margin-top: 10px;
}

.certificate-title {
  margin-bottom: 20px;
}

/* .cert-subtitle {
  font-family: "Amiri", sans-serif;
  margin-bottom: 40px;
} */

.lineage-table-title {
  font-family: "Amiri", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.table-footer-text {
  font-family: "Amiri", sans-serif;
}

.section-title {
  margin-bottom: 5px;
  font-size: 1.25rem;
}

.certificate-footer {
  width: 100%;
}

.qr-code {
  width: 80px !important;
  height: 80px !important;
}

.signature {
  width: 140px;
  position: absolute;
  top: -10px;
  left: -30px;
}

/* @Media 420px */
@media (max-width: 768px) {
  .certificate-wrapper {
    width: 100%;
  }
}

/* Print-specific styles */
@media print {
  .certificate {
    background-image: url("../images/border.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
  }

  .certificate-wrapper {
    width: 622px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    height: 100%;
  }

  .certificate-header {
    position: relative;
    top: 0px;
  }

  .cert-logo {
    width: 180px;
    height: auto;
    margin-bottom: 10px;
    /* margin-top: -20px; */
  }

  .lineage-table-title {
    font-family: "Amiri", sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 5px;
  }

  .table-footer-text {
    font-family: "Amiri", sans-serif;
  }

  .certificate-content {
    width: 100%;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
  }

  .lineage-item {
    position: relative;
    margin-bottom: 20px;
  }

  .lineage-name {
    position: relative;
    white-space: normal;
    border: 2px solid;
  }

  .lineage-name::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: 20px;
    height: 1px;
    background-color: black;
  }

  .lineage-item:not(:last-child) p::after {
    display: block;
  }

  .lineage-item:last-child p::after {
    display: none;
  }

  .indexNumber {
    position: absolute;
    font-size: 10px;
    top: 35px;
    left: 35%;
    transform: translateX(-35%);
    padding: 0px 6px;
    background-color: black;
  }

  .bio-table {
    font-size: 0.75rem;
    border-collapse: collapse;
    font-family: "Poppins", sans-serif;
  }

  .bio-table th,
  .bio-table td {
    padding: 4px 6px;
  }

  .certificate-section {
    margin-top: 10px;
  }

  .certificate-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  /* .cert-subtitle {
    font-family: "Amiri", sans-serif;
    font-size: 1rem;
    margin-bottom: 40px;
  } */

  .section-title {
    margin-bottom: 5px;
    font-size: 1.25rem;
  }

  .certificate-footer {
    max-width: 622px;
    font-size: 0.875rem;
  }

  .sign-border {
    padding: 10px !important;
    margin-bottom: 10px;
    padding-right: 40px !important;
    padding-top: 12px !important;
  }

  .arabika {
    font-family: "Amiri", sans-serif;
    font-size: 15px;
  }

  .arabika-small {
    font-family: "Amiri", sans-serif;
    font-size: 14px;
    position: relative;
    top: 20px;
  }

  .arabika-mid {
    font-family: "Amiri", sans-serif;
    font-size: 18px;
  }

  .arabika-big {
    font-family: "Amiri", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
  }

  .qr-text {
    font-size: 20px;
    margin-top: 5px;
  }

  .qr-code {
    width: 80px !important;
    height: 80px !important;
  }

  /* Hide non-essential elements when printing */
  button,
  .loading-indicator,
  .progress-indicator {
    display: none !important;
  }
}
